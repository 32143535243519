import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const SuccessPage = ({ location }) => (
  <Layout location={location}>
    <Seo title='Nachricht gesendet' />
    <section>
      <div className='container mt-5 text-center'>
        <h1 className='animate'>Nachricht gesendet</h1>
        <p>Vielen Dank für Ihre Nachricht.<br /><a href='/'>Hier geht es zurück zur Internetseite.</a></p>
      </div>
    </section>
  </Layout>
)

export default SuccessPage
